@font-face {
  font-family: 'BebasNeue-Regular';
  src: local('BebasNeue-Regular'), url('./fonts/BebasNeue-Regular.ttf') format('truetype');
}

.App {
  text-align: center;
  font-family: "BebasNeue-Regular";
}

#privacy-notice:hover {
  text-decoration: underline;
  cursor: pointer;
}

#footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 90px;
  /* border-top: 1px solid black; */
  background-color: white;
  padding-top: 10px;
  text-align: center;
}


#top-bar {
  margin-top: 10px;
}

#main-title {
  font-family: "BebasNeue-Regular";
  font-size: 1.5em;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#logos-div {
  display: flex;
  justify-content: flex-end;
}

.logos {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}

p {
  margin-bottom: 0 !important;
}

a {
  color: white!important;
  text-decoration: none!important;
}

a:hover {
  text-decoration: underline!important;
}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  font-size: 1em;
  top: 50px;
  color: white;
}

#music-container {
  animation: fadeIn 1s;
  margin-top: 50px;
}

.single-image {
  height: 100px;
  width: 100px;
}

.music-subtitle{
  margin-top: 10px;
  color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#home-container {
  animation: fadeIn 1s;
  color: white;
  font-size: 1.3em;
  padding-top: 60px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-container {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  color: white;
}

#new-music-message {
  margin-bottom: 50px;
  font-size: 1.2em;
  color: white;
}

.music-subtitle{
  margin-top: 10px;
  font-size: 1em;
}

html {
  height: 100%;
}

body { 
  background-image: url('./images/landing_portrait2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}


#gallery-container {
  animation: fadeIn 1s;
  font-size: 2em;
  padding-top: 180px;
  color: white;
}

#band-contact {
  margin-right: 0px;
}

@media only screen and (min-width: 770px) {
  /* For desktop: */
  #main-title {
    font-size: 5em;
  }

  #band-contact {
    margin-right: 35px;
  }

  a {
    color: white!important;
    text-decoration: none!important;
  }
  
  a:hover {
    text-decoration: underline!important;
  }

  .logos {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .menu-items {
    max-width: 300px;
    font-size: 1.4em;
    display: flex;
    flex-direction: row;
    width: 250px;
    justify-content: space-evenly;
    left: 50px;
    top: 9px;
  }

  .menu-container {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
  }

  #music-container {
    animation: fadeIn 1s;
    margin-top: 10px;
  }
  
  .single-image {
    height: 300px;
    width: 300px;
  }

  #home-container {
    animation: fadeIn 1s;
    font-size: 2.8em;
    padding-top: 180px;
  }

  #listen-now-text {
    max-width: fit-content;
    text-align: center;
  }

  .music-subtitle{
    margin-top: 10px;
  }

  #new-music-message {
    margin-bottom: 100px;
    color: white;
  }

  body { 
    background-image: url('./images/landing_landscape.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .swiper-image {
    height: 400px;
    width: auto;
  }

  #gallery {
    margin-top: 40px;
  }

  #footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  #gallery-container {
    animation: fadeIn 1s;
    font-size: 2.8em;
    padding-top: 180px;
    color: white;
  }
  
}